

import * as Card from "../toolkit/Card.bs.js";
import * as Head from "../components/head/Head.bs.js";
import * as I18n from "../i18n/I18n.bs.js";
import * as $$Text from "../toolkit/Text.bs.js";
import * as Route from "../Route.bs.js";
import * as Stack from "../toolkit/Stack.bs.js";
import * as React from "react";
import * as Layout from "../layout/Layout.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as IndexPage_Css from "./IndexPage_Css.bs.js";
import * as LimitedWidthContainer from "../toolkit/LimitedWidthContainer.bs.js";

function IndexPage(Props) {
  var pageContext = Props.pageContext;
  var locale = Belt_Option.getWithDefault(pageContext.locale, "en");
  I18n.changeLanguage(locale);
  var title = I18n.t("index.head.title");
  var description = I18n.t("index.head.description");
  var h1Text = I18n.t("index.body.h1");
  var h2Text = I18n.t("index.body.h2");
  var generatorRoutes = Route.generatorRoutesDetailed(locale);
  var generatorBlocks = Belt_Array.map(generatorRoutes, (function (route) {
          var text = I18n.t(route.code + ".link_text");
          return React.createElement("div", {
                      key: route.code
                    }, React.createElement("a", {
                          className: IndexPage_Css.cardLink,
                          href: route.path,
                          name: "Link"
                        }, React.createElement(Card.make, {
                              children: React.createElement($$Text.H3.make, {
                                    align: /* center */98248149,
                                    children: text
                                  })
                            })));
        }));
  return React.createElement(React.Fragment, undefined, React.createElement(Head.make, {
                  title: title,
                  description: description,
                  lang: locale,
                  children: null,
                  route: /* Index */0
                }, React.createElement("meta", {
                      content: "EtOXkFzGceA13nQsRf9evlUMxm9qGlMwOq-va8P0Ogg",
                      name: "google-site-verification"
                    }), React.createElement("meta", {
                      content: "39aa5e16e3e4946d",
                      name: "yandex-verification"
                    })), React.createElement(Layout.make, {
                  route: /* Index */0,
                  locale: locale,
                  children: React.createElement(Stack.make, {
                        gap: /* Lg */3,
                        children: null
                      }, React.createElement($$Text.H1.make, {
                            align: /* center */98248149,
                            children: h1Text
                          }), React.createElement($$Text.H2.make, {
                            align: /* center */98248149,
                            children: h2Text
                          }), React.createElement(LimitedWidthContainer.make, {
                            maxWidth: /* Px360 */0,
                            fullWidthMobile: true,
                            children: React.createElement(Stack.make, {
                                  gap: /* Md */2,
                                  children: generatorBlocks
                                })
                          }))
                }));
}

var Css;

var route = /* Index */0;

var make = IndexPage;

var $$default = IndexPage;

export {
  Css ,
  route ,
  make ,
  $$default ,
  $$default as default,
  
}
/* Card Not a pure module */
